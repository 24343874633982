import request from '@/utils/requests'
import qs from 'qs'


// 热搜列表
export const getHot = () => {
  return request({
    url: '/search/hot_search',
    method: 'post',
  })
}

// 搜索建议
export const getSuggest = data => {
  return request({
    url: '/search/suggest',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 搜索结果
export const getResult = data => {
  return request({
    url: '/search/result',
    method: 'post',
    data: qs.stringify(data)
  })
}