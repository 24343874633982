<template>
  <div class="">
    <van-tabs swipeable swipe-threshold="2" @click="onChange" @change="onChange">
      <van-tab v-for="item in typeList" :title="item.name" :name="item.id" :key="item.id">
        <!--添加loading状态-->
        <div v-show="isLoading" style="display: flex;justify-content: center">
          <van-loading size="24px" style="top: 50px">Loading...</van-loading>
        </div>
        <!--添加空数据状态-->
        <van-empty :image="require('@/assets/empty-image-default.png')" v-show="isEmpty"/>
        <!--数据展示-->
        <div class="card" v-for="item in list" :key="item.id" @click="toPlay(item.id)">
          <div class="cardImg">
            <van-image lazy-load width="3.1rem" height="4.37333rem" radius=".1rem" :src="item.vod_pic">
            </van-image>
          </div>
          <div class="cardInfo">
            <div class="vod-info">
              <div class="infoTitle">
                <div class="van-multi-ellipsis--l2">{{ item.vod_name }}</div>
                <div v-show="item.type_pid === 2">TV</div>
              </div>
              <div class="cardTime">{{ item.vod_year }}</div>
              <div class="cardTime" v-show="item.vod_director">
                {{ $t('director') }}: <span>{{ item.vod_director }}</span>
              </div>
              <div class="cardTime" style="line-height: 20px" v-show="item.vod_actor">
                {{ $t('actor') }}: <span>{{ item.vod_actor }}</span>
              </div>
            </div>
            <div>
              <div class="btn">
                {{$t('play')}}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {getResult} from '@/api/searchs'

export default {
  name: '',
  components: {},
  data() {
    return {
      typeList: [
        {id: 0, name: this.$t('all')},
        {id: 1, name: this.$t('movie')},
        {id: 2, name: this.$t('series')},
      ],
      list: [],
      isLoading: false,
      isEmpty: false,
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.onChange(0)
  },
  methods: {
    onChange(type_pid) {
      this.isLoading = true
      this.isEmpty = false
      let query = this.$route.query
      this.list = []
      const list = {
        kw: query['kw'],
        pn: 1
      }
      if (type_pid !== 0) {
        list['type_pid'] = type_pid
      }
      getResult(list).then(res => {
        this.list = res.result
        if (this.list.length <= 0) {
          this.isEmpty = true
        }
        this.isLoading = false
      })
    },
    toPlay(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-tabs__nav {
  background: none;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 0.8rem;
}

/deep/ .van-tab {
  font-family: Sofia Pro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: 0px;
  color: var(--title-color);
  opacity: 0.6;
}

/deep/ .van-tabs__line {
  display: none;
}

/deep/ .van-tab--active {
  background-color: var(--minor-color);
  border-radius: 2px;
  color: var(--title-color);
}

//卡片整体样式
.card {
  padding: 15px 10px;
  display: flex;
  font-family: Sofia Pro;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: var(--minor-text-color);
  border-top: 1px solid #292929;
  height: auto;
}

.card:first-child {
  border-top: 0;
}

/deep/ .van-image__error, .van-image__loading {
  background-color: transparent;
}

.cardInfo {
  margin-left: 0.2rem;
}

.infoTitle {
  width: 61vw;
  display: flex;
  align-items: center;
  margin-top: .1rem;
}

.infoTitle > div:first-child {
  font-size: 16px;
  color: var(--title-color);
  line-height: 16px;
  opacity: 0.8;
}

.infoTitle > div:last-child {
  font-size: 10px;
  color: var(--minor-text-color);
  opacity: 0.99;
  line-height: 16px;
  margin-left: 10px;
  padding: 5px;
  background-color: var(--minor-color);
}

.cardTime {
  font-size: 12px;
  line-height: 14px;
  color: var(--minor-text-color);
  padding: 5px 0;
}

.btn {
  width: 26vw;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-color);
  padding: 6px 7px;
  background-color: #ef233c;
  border-radius: 4px;
  text-align: center;
}

.vod-info{
  min-height: 125px;
}

/deep/ .van-image {
  display: block;
}
</style>
